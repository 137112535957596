import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Fade } from "react-slideshow-image";

export default function Carousel({ carousel }) {

  return (
    <div
      className="slide-container"
      // style={{ minHeight: 400 }}
    >
      <Fade>
        {carousel.objects.map((fadeImage, index) => (
          <div
            className="each-fade"
            key={index}
            style={{ position: "relative" }}
          >
            <div className="image-container">
              {fadeImage.image?.childImageSharp ? (
                <GatsbyImage
                  image={getImage(fadeImage.image)}
                  objectPosition="top left"
                  style={{
                    maxHeight: "calc(100vh - 128px)",
                    minHeight: 300
                  }}
                  layout="constrained"
                  alt="Imagem de fundo"
                />
              ) : (
                <img
                  src={fadeImage.image}
                  objectPosition="top left"
                  style={{
                    maxHeight: "calc(100vh - 128px)",
                    width: "100%",
                    minHeight: 300
                  }}
                  alt="Imagem de fundo"
                />
              )}
            </div>
            <div
              className="has-text-centered is-flex is-flex-direction-column"
              style={{
                position: "absolute",
                top: "35%",
                left: "0px",
                right: "0px",
                marginLeft: "auto",
                marginRight: "auto",
                width: "80%",
              }}
            >
              <div>
                <h1
                  className="has-text-weight-bold is-size-4-mobile is-size-2-tablet is-size-2-widescreen"
                  style={{
                    boxShadow:
                      "#e3bc33 0.5rem 0px 0px, #e3bc33 -0.5rem 0px 0px",
                    backgroundColor: "#e3bc33",
                    color: "white",
                    lineHeight: "1",
                    textTransform: "uppercase",
                    padding: "0.25em",
                    display: "inline-block",
                  }}
                >
                  {fadeImage.title}
                </h1>
              </div>
              <div>
                <h3
                  className="has-text-weight-bold is-size-6-mobile is-size-5-tablet is-size-4-widescreen"
                  style={{
                    boxShadow:
                      "#e3bc33 0.5rem 0px 0px, #e3bc33 -0.5rem 0px 0px",
                    backgroundColor: "#e3bc33",
                    color: "white",
                    lineHeight: "1",
                    padding: "0.25rem",
                    marginTop: "0.5rem",
                    display: "inline-block",
                  }}
                >
                  {fadeImage.subtext}
                </h3>
              </div>
            </div>
          </div>
        ))}
      </Fade>
    </div>
  );
}

Carousel.propTypes = {
  carousel: PropTypes.object,
};
