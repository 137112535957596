import React from "react";
import SectionTitle from "./SectionTitle";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

const About = ({ title, text, img }) => {
  return (
    <section className="section" id="about">
      <SectionTitle text={title} />
      <div className="container columns">
        <div className="column is-5 is-flex is-align-items-center is-justify-content-center">
          {img?.url ? (
            <img
              src={img}
              style={{
                borderRadius: "100%",
                width: 250,
                height: 250
              }}
              alt=""
            />
          ) : (
            <GatsbyImage
              image={img}
              style={{
                borderRadius: "100%",
                width: 250,
                height: 250
              }}
              placeholder="blurred"
              alt=""
              // aspectRatio={1}
            />
          )}
        </div>
        <div className="column is-7 is-flex is-align-items-center is-justify-content-center">
          <p className="is-size-5">{text}</p>
        </div>
      </div>
    </section>
  );
};

About.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  text: PropTypes.string,
};

export default About;
