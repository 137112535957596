import React, { useState } from "react";
import user from "../img/user-icon.svg";
import emailIcon from "../img/email-icon.svg";
import Recaptcha from "react-google-recaptcha";
import SectionTitle from "./SectionTitle";
import { StaticImage } from "gatsby-plugin-image";
const RECAPTCHA_KEY = "6LdGoDgeAAAAAC63zg7kUfYXT9Li8AUcD8uwiN_3";

const Contact = () => {
  const [state, setState] = React.useState({});
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (recaptcha == null) {
      setError("Por favor, faça o reCAPTCHA");
      return;
    }

    setIsSubmiting(true);
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => {
        setIsSubmiting(false);
        setIsSuccess(true);
      })
      .catch((error) => {
        setIsSubmiting(false);
        alert(error);
      });
  };

  return (
    <div id="contact" style={{ display: "grid" }}>
      <StaticImage
        layout="fullWidth"
        className="background-img"
        alt=""
        src="../img/map.jpg"
        quality={90}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <section
          style={{
            width: "100%",
            position: "absolute",
            top: "0",
          }}
          className="section has-text-white"
        >
          <SectionTitle
            inverted
            className="has-text-white"
            text="Entrar em contato"
          />
          <div className="columns">
            <div className="column is-1"></div>
            <div data-aos="flip-left" className="column is-4">
              <h1 className="is-size-3 has-text-weight-semibold">
                Quer <span style={{ color: "#e3bc33" }}>saber mais</span> ou
                tirar alguma <span style={{ color: "#e3bc33" }}>dúvida</span>?
              </h1>

              <h1 className="is-size-4 pt-5 has-text-weight-medium">
                Estou à disposição para ajudar! Entre em contato comigo.
              </h1>
            </div>
            <div className="column is-1"></div>

            <div className="column is-6">
              <form
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <input type="hidden" name="form-name" value="contact" />
                <div className="field">
                  <div className="control has-icons-left has-icons-right">
                    <input
                      id="name"
                      className="input"
                      type="text"
                      placeholder="Digite seu nome"
                      onChange={handleChange}
                      required
                    />
                    <span className="icon is-small is-left">
                      <img
                        src={user}
                        width={20}
                        style={{ fill: "red" }}
                        alt="WhatsApp"
                      />
                    </span>
                  </div>
                </div>

                <div className="field">
                  <div className="control has-icons-left has-icons-right">
                    <input
                      id="email"
                      className="input"
                      type="email"
                      placeholder="Digite seu email"
                      onChange={handleChange}
                      required
                    />
                    <span className="icon is-left">
                      <img
                        src={emailIcon}
                        width={20}
                        style={{ fill: "red" }}
                        alt="WhatsApp"
                      />
                    </span>
                  </div>
                </div>

                <div className="field">
                  <div className="control">
                    <textarea
                      htmlFor="message"
                      className="textarea"
                      placeholder="Digite sua mensagem"
                      name="message"
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                </div>

                {/* <div className="field">
                  <div className="control">
                    <label className="checkbox">
                      <input type="checkbox" required /> Você aceita nossos
                      <Link className="px-1" to="/contact">
                        termos e condições de privacidade
                      </Link>
                    </label>
                  </div>
                </div> */}
                <div className="field">
                  <Recaptcha
                    sitekey={RECAPTCHA_KEY}
                    onChange={(e) => setRecaptcha(e)}
                  />
                </div>

                <div className="field">
                  <div className="control">
                    {isSuccess === false ? (
                      <button
                        type="submit"
                        className={`button ${isSubmiting ? "is-loading" : ""}`}
                      >
                        Enviar
                      </button>
                    ) : (
                      <p className="help is-success">Enviado com sucesso</p>
                    )}
                  </div>
                  {error != null ? (
                    <p className="help is-danger">{error}</p>
                  ) : null}
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
