import React from "react";
import whats from "../img/social/whatsapp-icon-white.svg";

export default function WhatsAppButton() {
  return (
     <a
     className="float"
     href="https://wa.me/555192413797"
     target="_blank"
     rel="noopener noreferrer"
   >
         <img src={whats} alt="WhatsApp" />
   </a>
  );
}
