import React from "react";
import SectionTitle from "./SectionTitle";

const Services = ({ title, list }) => {
  return (
    <section className="section" id="services">
      <SectionTitle text={title} />
      <div className="container mt-6 mb-6 is-flex is-justify-content-space-around is-align-items-center is-flex-wrap-wrap">
        {list.map((item, index) => (
          <ServiceCard
            key={item.title + "-" + index}
            img={item.image}
            title={item.title}
            index={index}
          />
        ))}
      </div>
    </section>
  );
};

const ServiceCard = ({ title, img, index }) => {
  let animation =
    img.publicURL === undefined
      ? {}
      : { "data-aos": "fade-right", "data-aos-delay": 800 + 200 * index };

  return (
    <div
      {...animation}
      style={{ width: 200 }}
      className="ServiceCard mb-2 is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
    >
      <figure className="image is-96x96 mb-3">
        <img
          width={96}
          height={96}
          src={img?.publicURL ?? img}
          color="red"
          alt="Icone"
        />
      </figure>
      <p className="is-size-5 has-text-centered">{title}</p>
    </div>
  );
};

export default Services;
