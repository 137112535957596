import React from "react";

const SectionTitle = ({ text, inverted }) => {
  return (
    <div className="container pt-2 pb-5 is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
      <p className={"is-size-3 has-text-weight-medium pb-1 "+ (inverted ? "has-text-white" : "has-text-black")}>{text}</p>
      <div className={inverted ? "has-background-white" : "has-background-black"} style={{width: 80, height: 2}} />
    </div>
  );
};

export default SectionTitle;
