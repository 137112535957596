import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import "react-slideshow-image/dist/styles.css";
import "aos/dist/aos.css";
import AOS from "aos";

import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";
import Carousel from "../components/Carousel";
import Contact from "../components/Contact";
import Services from "../components/Services";
import About from "../components/About";
import SectionTitle from "../components/SectionTitle";
import WhatsAppButton from "../components/WhatsAppButton";

// eslint-disable-next-line
export const IndexPageTemplate = ({ carousel, about, services }) => {
  useEffect(() => {
    AOS.init();
  }, []);

  const aboutImage = getImage(about.image) || about.image;

  return (
    <div>
      <WhatsAppButton />
      <Carousel carousel={carousel} />
      <About text={about.description} title={about.title} img={aboutImage} />
      <Services list={services.services} title={services.title} />
      <SectionTitle text="Últimas Postagens" />
      <div className="column is-10 is-offset-1">
        <div className="content">
          <div className="column is-12">
            <BlogRoll />
            <div className="column is-12 has-text-centered">
              <Link className="button" to="/blog">
                Ver mais
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Contact />
    </div>
  );
};

IndexPageTemplate.propTypes = {
  carousel: PropTypes.object,
  about: PropTypes.object,
  services: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout slug="/">
      <IndexPageTemplate
        carousel={frontmatter.carousel}
        about={frontmatter.about}
        services={frontmatter.services}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        carousel {
          objects {
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 1920 layout: FULL_WIDTH)
              }
            }
            title
            subtext
          }
        }
        about {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                width: 250
                height: 250
                placeholder: BLURRED
                layout: FIXED
              )
            }
          }
        }
        services {
          services {
            image {
              publicURL
            }
            title
          }
          title
          description
        }
      }
    }
  }
`;
